<template>
    <div style="margin: 8px;font-weight: bold">Logging out...</div>
</template>
<script>
export default {
    name: "Logout",
    mounted() {
        // TODO: make a AuthGuard and make it reactive, because location.reload() is not the solution
        if (this.$commons.getFromStorage('loggedIn')) {
            window.localStorage.clear() // remove everything
            window.location.reload()
        } else {
            this.$router.push({name: 'Login'})
        }
    }
}
</script>